@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background: white;
}

#root {
  height: 100%;
}

.custom-phone-input .ui.input {
  display: block;
}

:root {
  --PhoneInputCountryFlag-aspectRatio: 1.6666;
}

/* .customModal {
  background: red;
  max-width: 500px;
  max-height: 400px;
  width: 100%;
} */

.carousel .slide img {
  max-height: 500px; /* Set the maximum height */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures the image fits within the container without being cropped */
}

.carousel .control-dots .dot {
  width: 16px;
  height: 16px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background-color: black;
}

.carousel.carousel-slider .control-arrow {
  padding: 8px;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}

.carousel .control-next.control-arrow:before {
  border-left: 16px solid #fff;
}

.carousel .control-prev.control-arrow:before {
  border-right: 16px solid #fff;
}
