html {
  scroll-behavior: smooth;
}

body {
  background: white !important;
}

.uppercase {
  text-transform: uppercase;
}

.react-multi-carousel-list {
  overflow: visible;
}

.react-multi-carousel-dot-list {
  bottom: -38px;
}

.video-icon {
  position: absolute;
  left: 2px;
  font-size: 18px;
  top: 2px;
  color: #e6c8c8;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.ui.dropdown.text-center {
  text-align: center;
}

.distance-class-form .react-date-picker__inputGroup__divider {
  font-size: 30px;
}
.react-date-picker__inputGroup__leadingZero {
  display: none;
}

.react-date-picker.react-date-picker--closed.react-date-picker--enabled {
  width: 60px;
}

.react-datetime-picker .react-datetime-picker__wrapper {
  border-radius: 4px;
  padding: 0px;
  border: none;
}

.react-datetime-picker__inputGroup input {
  height: 13px;
}

.react-date-picker .react-date-picker__wrapper {
  border-radius: 4px;
  padding: 0px;
  border: none;
}

.react-date-picker__inputGroup input {
  height: 13px;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
video:focus {
  outline: none;
}

body {
  /* overflow: hidden; */
  /* -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none; */
}

.Toastify__toast {
  min-height: auto !important;

  text-align: center;
}
.Toastify .Toastify__toast--success {
  /* background: linear-gradient(45deg, #94dc44 30%, #6be642 90%);
  color: #137016;
  box-shadow: 0px 0px 13px #98c49a; */
}

.Toastify .Toastify__toast--warning {
  /* color: #6e5a07;
  box-shadow: 0px 0px 13px #d9ba8963;
  background: linear-gradient(45deg, #d4dc5d 30%, #eea434 90%); */
}

.Toastify .Toastify__toast--info {
  /* background: linear-gradient(45deg, #3e9de0 30%, #37bcee 90%);
  color: #031b2a;
  box-shadow: 0px 0px 13px #598ea575; */
}

.Toastify .Toastify__toast--error {
  /* background: linear-gradient(45deg, #fe6b8b 30%, #c26ab8 90%);
  color: #5e0c03;
  box-shadow: 0px 0px 4px #c669b5; */
}

.Toastify .Toastify__toast {
  border-radius: 4px;
}

.Toastify .Toastify__toast-body {
  margin: 2px;
  font-size: 16px;
}

/* Modal container */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.modal.open {
  display: flex;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%; /* Modal width (can shrink based on screen size) */
  max-width: 900px;
  height: 70vh; /* Modal height (can shrink based on screen size) */
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.close-btn {
  align-self: flex-end;
  margin-bottom: 20px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

/* Carousel wrapper to ensure it's responsive inside modal */
.carousel-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Making the carousel and images responsive */
.carousel .slide img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
